import React from "react"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { graphql, Link } from "gatsby"
import marked from "marked"
import PageHeading from "@components/pageHeading"
import HeadingBG from "@images/pexels-max-vakhtbovych-6284230.jpeg"
import Fade from "react-reveal/Fade"
import PlaceholderImage from "@images/placeholder-image.jpg"

class BlogTemplate extends React.Component {
  render() {
    const { post } = this.props.data
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location}>
        <SeoComponent title={post.title} description={post.excerpt} />
        <PageHeading
          bgImage={
            post.mainImage && post.mainImage.asset && post.mainImage.asset.url
              ? post.mainImage.asset.url
              : HeadingBG
          }
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1 className="text-center lined-heading text-white">
                  {post.title}
                </h1>
                <span className="d-block text-uppercase text-center text-white small">
                  Posted on {post.publishedAt}
                </span>
              </div>
            </div>
          </div>
        </PageHeading>

        <div className="py-10 dynamic-page-content-wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(post.body || ""),
                  }}
                />
              </div>
            </div>
            <div className="pt-5 mt-5 border-top">
              <div className="row justify-content-center">
                {previous ? (
                  <div className="col-lg-4 col-md-5 mb-5 mb-md-0">
                    <h2 className="page-content-h2 text-left text-md-center">
                      Previous
                    </h2>
                    <Fade>
                      <div className="blog-item-wrapper">
                        <Link
                          to={`/blog/` + previous.slug.current}
                          aria-label={previous.title}
                        >
                          <div className="blog-img">
                            <div
                              className="image-bg"
                              style={{
                                backgroundImage: `url(${
                                  previous.mainImage &&
                                  previous.mainImage.asset &&
                                  previous.mainImage.asset.url
                                    ? previous.mainImage.asset.url
                                    : PlaceholderImage
                                })`,
                              }}
                            ></div>
                          </div>
                        </Link>
                        <span className="d-block text-uppercase my-3 small text-muted">
                          {previous.publishedAt}
                        </span>
                        <Link
                          className="blog-title"
                          to={`/blog/` + previous.slug.current}
                        >
                          <h2 className="h5 mb-3">{previous.title}</h2>
                        </Link>
                        <p>{previous.excerpt}</p>
                        <Link
                          className="read-more-link"
                          to={`/blog/` + previous.slug.current}
                        >
                          Read More
                        </Link>
                      </div>
                    </Fade>
                  </div>
                ) : null}
                {next ? (
                  <div className="col-lg-4 col-md-5">
                    <h2 className="page-content-h2 text-left text-md-center">
                      Next
                    </h2>
                    <Fade>
                      <div className="blog-item-wrapper">
                        <Link
                          to={`/blog/` + next.slug.current}
                          aria-label={next.title}
                        >
                          <div className="blog-img">
                            <div
                              className="image-bg"
                              style={{
                                backgroundImage: `url(${
                                  next.mainImage &&
                                  next.mainImage.asset &&
                                  next.mainImage.asset.url
                                    ? next.mainImage.asset.url
                                    : PlaceholderImage
                                })`,
                              }}
                            ></div>
                          </div>
                        </Link>
                        <span className="d-block text-uppercase my-3 small text-muted">
                          {next.publishedAt}
                        </span>
                        <Link
                          className="blog-title"
                          to={`/blog/` + next.slug.current}
                        >
                          <h3 className="h5 mb-3">{next.title}</h3>
                        </Link>
                        <p>{next.excerpt}</p>
                        <Link
                          className="read-more-link"
                          to={`/blog/` + next.slug.current}
                        >
                          Read More
                        </Link>
                      </div>
                    </Fade>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      mainImage {
        asset {
          url
        }
      }
    }
  }
`
